import * as React from "react"
import styled from "styled-components"
import { BasketContext } from "../../context/BasketProvider"
import { useContext } from "react"
import * as colors from "../../constants/colors"
import VendorBasketItems from "./VendorBasketItems"
import { groupBasketItemsByVendor } from "../../util/basketUtil"

const EmptyBasketMessage = styled.h3`
  color: ${colors.darkGrey};
  text-align: center;
`

interface Props {
  dateError: string
  resetDateError: () => void
}

const GroupedBasketContents = ({ dateError, resetDateError }: Props) => {
  const { basket, updateProduct } = useContext(BasketContext)
  const [vendorMap, groupedItems] = groupBasketItemsByVendor(basket.items)
  return (
    <div>
      {!basket.items.length && (
        <EmptyBasketMessage>PLEASE ADD SOME PRODUCTS TO PROCEED WITH CHECKOUT</EmptyBasketMessage>
      )}
      {Object.entries(groupedItems).map(([vendorSlug, items]) => (
        <VendorBasketItems
          key={vendorSlug}
          items={items}
          dateError={dateError}
          resetDateError={resetDateError}
          updateProduct={updateProduct}
          vendor={vendorMap[vendorSlug]}
        />
      ))}
    </div>
  )
}

export default GroupedBasketContents
